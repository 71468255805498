.scroll-top {
  position: fixed;
  //width: 100%;
  bottom: 30px;
  right: 50px;
  align-items: center;
  height: 45px;
  font-size: 45px;
  justify-content: flex-end;
  margin-right: 50px;
  z-index: 1000;
  //margin-left: 68px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.9;
  fill: #f8e253;
  stroke: #da2c38;
}

@media (min-width: 1980px) {
  .scroll-top {
    bottom: 30px;
    right: 0;
  }
}

@media (max-width: 743px) {
  .scroll-top {
    bottom: 30px;
    right: -30px;
  }
}

.scroll-top:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

// ----------- header -----------
.header {
  //box-shadow: 2px 2px 12px #00000014;
  .header__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 67px calc(100vw / 20);

    .logo {
      display: flex;
      //padding: 5px 5px 5px 25px;

      a {
        text-decoration: none;
        //font-family: 'Ballet', cursive;
        //font-family: 'Merienda', cursive;
        font-family: 'Monsieur La Doulaise', cursive;

        font-size: 40px;
        //letter-spacing: 3px;
      }
    }
  }
}

@media (max-width: 1540px) {
  .header .header__items {
    //background: #f6f7f2;
    //border: solid 1px #2a3137;
    //border-top: solid 1px transparent;
    padding: 45px calc(100vw / 20);
  }
}
@media (max-width: 500px) {
  .header .header__items {
    //background: #f6f7f2;
    //border: solid 1px #2a3137;
    //border-top: solid 1px transparent;
    padding: 5px calc(100vw / 20);
  }
}

// ----------- navbar -----------
.navbar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  position: relative;

  .path-restaurant {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-right: 50px;
    margin-top: 6px;
    text-transform: uppercase;

    .path-restaurant__item {
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
    @media (max-width: 660px){
      .path-restaurant__item {
        display: none;
      }
    }
    //.path-restaurant__grimbergen {
    //  margin: 0 20px;
    //
    //  a {
    //    text-decoration: none;
    //  }
    //}
  }

  .internalization-toggle {
    width: 30px;
    cursor: pointer;
    display: none;

    .container-language {
      padding: 3px 0 0 8px;

      span {
        display: inline-block;
        margin-bottom: 3px;
        color: white;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 49px;
      right: -44px;
      bottom: auto;
      left: auto;
      z-index: 10;
      display: flex;
      flex-direction: column;
      transform: translate(-40px, 0px);
      border: solid 3px #c1ab53;
      //border-radius: 30px;
      cursor: pointer;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.92);

      //&::after {
      //  content: '';
      //  width: 10px;
      //  height: 10px;
      //  display: block;
      //  position: absolute;
      //  z-index: 11;
      //  //border: 0;
      //  border-left: 1px solid #454545;
      //  border-top: 1px solid #454545;
      //  transform: rotate(45deg);
      //  margin-left: -2px;
      //  left: 50%;
      //  top: -6px;
      //  background-color: #547b25;
      //}

      .dropdown-menu__button_top {
        padding: 8px 20px 6px;
        border: none;
        background-color: #547b25;
        //border-top-right-radius: 30px;
        //border-top-left-radius: 30px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
      }

      .dropdown-menu__button_middle {
        padding: 6px 20px 6px;
        border: none;
        background-color: #547b25;
        border-radius: 0;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
      }

      .dropdown-menu__button_bottom {
        padding: 4px 20px 8px;
        border: none;
        background-color: #547b25;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        //border-bottom-right-radius: 30px;
        //border-bottom-left-radius: 30px;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
      }

      .active_button {
        color: #5f5f5f;
        background-color: #94b45c;
      }
    }

    .display-none {
      display: none;
    }
  }

  .sidebar-toggle, .theme-toggle {
    margin-left: 25px;
    cursor: pointer;

  }
}

//@media (max-width: 660px) {
.navbar {
  //.path-restaurant {
  //  display: none;
  //}
  .internalization-toggle {
    display: block;
  }

  .sidebar__internalization-toggle {
    display: none;
  }
}

//}

// ----------- overlay -----------
.overlay {
  height: 100vh;
  width: 100%;
  z-index: 5;
  top: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 640px) {
  .overlay {
    height: 100vh;
  }
}

.overlay-none {
  display: none;
}

// ----------- sidebar -----------
.sidebar {
  background-color: #060b26;
  //width: 250px;
  //height: 100vh;
  position: fixed;
  //top: 56.6px;
  right: -100%;
  transition: 0.75s;
  z-index: 6;
  top: 0;
  bottom: 0;

  .sidebar__wrap {
    position: relative;
    height: calc(100vh - 0px);
    width: 380px;
    padding: 18rem 5rem;
    background-color: #c1ab53;
    border-left: solid 1px rgba(146, 149, 155, 0.28);
    display: flex;
    flex-direction: column;

    a {
      margin: 5px 0;
      box-sizing: content-box;
      cursor: pointer;
      text-decoration: none;
      //width: 380px;

      h3 {
        color: #111111;
        margin: 0;
      }

    }


  }
}

.sidebar__internalization-toggle {
  padding: 45px 0 0;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    color: white;
  }

  .black {
    color: black;
  }

  .active {
    color: #ffffff;
  }
}

#sidebar-toggle {
  display: none;
}

//@media (max-width: 660px){
#sidebar-toggle {
  display: flex;
  width: 30px;
  height: 17px;
  position: relative;
  //margin: 30px auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 200;
}

//}


#sidebar-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #c5a447;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#sidebar-toggle span:nth-child(1) {
  top: 0;
}

#sidebar-toggle span:nth-child(2) {
  top: 7px;
}

#sidebar-toggle span:nth-child(3) {
  top: 7px;
}

#sidebar-toggle span:nth-child(4) {
  top: 14px;
}

#sidebar-toggle.open span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
  background: #ffffff;
}

#sidebar-toggle.open span:nth-child(2) {
  transform: rotate(45deg);
  background: #ffffff;
}

#sidebar-toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
  background: #ffffff;
}

#sidebar-toggle.open span:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
  background: #ffffff;
}

.active {
  right: 0;
  transition: 0.3s;
  //transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

.sidebar .sidebar__wrap a h3.active-link {
  color: #ffffff;
}

@media (max-width: 440px) {
  .sidebar {
    width: 100%;

    .sidebar__wrap {
      position: relative;
      height: calc(100vh - 0px);
      padding: 130px 30px 0;
      background-color: #c1ab53;
      //border-top: solid 1px #0f172a47;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: auto;

      a h3 {
        //font-size: 25px;
      }

      a h3.active-link {
        border-left: none;
        padding-left: 0;
        //border-bottom: solid 3px #a292c3;
      }
    }
  }
}

// ----------- end sidebar -----------