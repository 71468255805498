.contact__flex {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 50px 0 0 0;
}

@media screen and (max-width: 800px) {
  .contact__flex {
    flex-direction: column;
    align-items: center;
    padding: 50px 30px 30px 30px;
  }
}

.success {
  color: #007500;
  margin: 0;
}

.my__form {

}

.form__wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 30px;
  .form__title {
    margin: 0;
    padding: 30px 0;
  }
}
.form__description {
  padding: 0 0 20px;
  max-width: 60vw;
}
.fields {
  display: flex;
  flex-direction: column;
}

.field {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 75px;

  input, input:focus-within {
    box-sizing: border-box;
    border: solid 1px #454545;
    background: #f5f5f5;
    height: 40px;
    width: 30vw;
    border-radius: 20px;
    padding: 10px 15px;
  }
  input,
  input:-webkit-autofill,
  textarea,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    background-color: #e2d9f1;
    &, &:hover, &:focus, &:active {
      -webkit-box-shadow: 0 0 0px 1000px #e2d9f1 inset !important;
    }
  }
  @media screen and (max-width: 800px) {
    input, input:focus-within {
      width: 60vw;
    }
    .form__description {
      max-width: 60vw;
    }
  }

  @media screen and (max-width: 450px) {
    input, input:focus-within {
      width: 90vw;
    }
    .form__description {
      max-width: 90vw;
    }
  }

  p.errorMsg {
    margin: 0;
    color: #ff0076;
    display: inline-block;
  }
}

.field__button {


}

.message {
  height: 120px;

  textarea, textarea:focus-within {
    box-sizing: border-box;
    border: solid 1px #454545;
    background: #f5f5f5;
    height: 60px;
    width: 40vw;
    color: #1c1b1b;
    border-radius: 20px;
    padding: 10px 15px;
    resize: none;
    outline: none;
  }

  @media screen and (max-width: 800px) {
    textarea, textarea:focus-within {
      width: 70vw;
    }
    .form__description {
      width: 70vw;
    }
  }
  @media screen and (max-width: 450px) {
    textarea, textarea:focus-within {
      width: 90vw;
    }
  }
}

.field button {
  width: 100px;
  border-radius: 20px;
  padding: 10px;
  border: solid 1px #454545;
  background-color: #dadada;
}

.hover {
  &:hover {
    box-shadow: 5px 5px 10px #6b6b6b;
  }
}

@media screen and (min-width: 800px) {
  .form__description {
    width: 40vw;
  }
}
@media screen and (max-width: 800px) {
  .form__description {
    width: 70vw;
  }
}

@media screen and (max-width: 450px) {
  .form__description {
    max-width: 90vw;
  }
}

.contact {
  height: auto;
  margin-top: 30px;
}

.address {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  overflow-y: hidden;
  margin-bottom: 30px;


  h3, p {
    margin: 0;
    display: block;
    padding-rigth: 50px;
  }
}

.address__contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 30px;
}

.address__phone__email {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: #8b5fe3;
    }
  }
}

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}