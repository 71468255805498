html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: visible;
  width: 100%;
  position: relative;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

.dark {
  background-color: #23292e;
}
body{
  background-image: url('../images/bg.webp');
  background-position: 50% 50%;
  object-fit: cover;
  vertical-align: middle;
  background-size: cover;
  //background-repeat: no-repeat;
  background-repeat: repeat;
  //background-size: auto;
  height: 100vh;
  position: relative;
  z-index: 2;
}
@media (max-width: 1540px){
  body{
    background-image: url('../images/bg.jpg');
    background-position: 50% 100%;
    //background-repeat: repeat;
    //background-size: auto;
  }
}

@media (max-width: 745px) {
  body{
    //background-position: 0 100%;
    background-repeat: repeat;
    background-size: auto;
  }
}
main {
  color: #ffffff;
  //padding: 10px;
  overflow: visible!important;
  height: auto;
  position: relative;

}

.container {
  max-width: 1170px;
  margin: 0 auto;

}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}