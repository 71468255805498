.main {
  overflow-y: hidden;
  .restaurant-top {
    position: relative;
    .restaurant-top__items {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;

      img {
        width: auto;
        height: 300px;
        z-index: 1;
      }
      h1 {
        //font-family: 'Ballet', cursive;
        //font-family: 'Merienda', cursive;
        font-family: 'Monsieur La Doulaise', cursive;
        font-size: 40px;
        font-weight: 300;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 400px;
      background: #fff;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      z-index: 1;
      box-shadow: 20px 20px 47px #bebebe, -20px -20px 47px #ffffff;
    }
  }
  .restaurant-menu {
    //margin-top: 50px;
    position: relative;
    //padding: 1.5rem calc(100vw / 20);
    .restaurant-menu__items {
      width: 100%;
      padding: 1.5rem 20px;
      z-index: 2;

      // ------------ Tab -------------

      .container-tab {
        //width: 100vw;
        //height: 611px;
        /* background-color: #eeeded; */
        //border: 1px solid rgba(0, 0, 0, 0.3);
        max-width: 1170px;
        padding: 0 15px;
      }

      .tab-list {
        max-width: 1170px;
        margin: 0 auto;
        height: 51px;
        display: flex;
        justify-content: space-evenly;
        list-style: none;
        font-size: 18px;
        padding: 0 0 20px;
        border-bottom: solid 1px #2a3137;
      }
      .menu-tabs-buttons {
        position: absolute;
        top: 125px;
        left: 35px;
        overflow-y: auto;
        z-index: 5;
        width: 150px;
        border-right: solid 1px #2a3137;
        .wrapper_menu_tabs_buttons {
          //position: fixed;
          overflow-y: auto;
          max-height: calc(100vh - 7rem);
        }
        .menu-tab__button {
          padding: 2px 0 2px 5px;

          a {
            text-decoration: none;
            color: #ffffff;
            border-bottom: solid 1px #ffffff;
          }
        }
        .menu-tabs-lang {
          margin-top: 20px;
          cursor: pointer;
          span {
            font-weight: 600;
            margin-left: 10px;
            color: #ffffff;
          }
          .active {
            color: #747474;
          }
        }
      }
      .active-scroll {
        position: sticky;
        top: 0;
        background-color: #141414;
        //height: 80px;
        z-index: 5;
        border-bottom: solid 1px #2a3137;

        .menu-tabs-buttons {
          position: absolute;
          top: 51px;
          left: 1px;
          padding: 0 0 10px 0;
          //background-color: #ffffff;
          width: 160px;
          z-index: 5;
        }
        //.active-buttons{
        //  display: block;
        //  top: 50px;
        //  left: 0;
        //  background-color: #498150;
        //  //height: 80px;
        //  z-index: 5;
        //}
      }

      .tabs {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        span {
          padding: 18px 10px 4px;
          border-bottom: solid 3px transparent;
          color: #e3c82e;
        }
      }

      //.tabs:not(:last-child) {
      //  border-right: 1px solid rgba(0, 0, 0, 0.3);
      //}

      .active-tabs span {
        //background: #eeeded;
        border-bottom: solid 3px #c5a447;
      }

      .active-tabs::before {
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 4px;
        //background: #5893f1;
      }

      .content-container {
        display: flex;
        overflow: visible;
      }

      .content {
        display: none;
        //background-color: #eeeded;
        padding: 50px 10px;
        position: relative;
        overflow: visible;

        .content_menu-items {
          padding-top: 50px;
          margin-top: -50px;
          span.content_menu-title {
            padding-left: 170px;
            font-weight: 700;
            color: #90ba64;
          }
          .not-padding {
            padding-left: 0;
          }
        }
        .content_menu-item {
          display: flex;
          justify-content: space-between;
          position: relative;
          padding: 5px 10px 0 170px;

          p {
            border-bottom: solid 1px #2a3137;
          }
          span {
            display: flex;
            justify-content: flex-end;
            min-width: 70px;
            margin-left: 30px;
          }
        }
        .not-padding {
          padding-left: 0;
        }
      }

      .active-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        background: #141414bf;
        @media (max-width: 500px){
          //background: transparent;
        }
      }

      .restaurant-menu__title {
        text-align: center;

      }
    }
  }
}
@media (max-width: 660px) {
  section > div > div > ul > div {
    display: none;
  }
  .main
    .restaurant-menu
    .restaurant-menu__items
    .content
    .content_menu-items
    span.content_menu-title,
  .main .restaurant-menu .restaurant-menu__items .content .content_menu-item {
    padding-left: 0;
  }
}
@media (max-width: 765px) {
  .main .restaurant-menu .restaurant-menu__items .tab-list {
    justify-content: space-between;
  }
  .main .restaurant-menu .restaurant-menu__items .tabs span {
  }
}
@media (max-width: 559px) {
  .main .restaurant-menu .restaurant-menu__items {
    padding: 1.5rem 0;
  }
}

@media (max-width: 400px) {
  .main {
    .restaurant-top {
      .restaurant-top__items {
        img {
          width: auto;
          height: 150px;
        }
        h1 {
          //font-family: 'Ballet', cursive;
          //font-family: 'Merienda', cursive;
          font-family: 'Monsieur La Doulaise', cursive;
          font-size: 40px;
          font-weight: 300;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 260px;
        background: #fff;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        z-index: 1;
        box-shadow: 20px 20px 47px #bebebe, -20px -20px 47px #ffffff;
      }
    }
  }
}
// ============= test-grid ==============

p {
  margin: 0;
}
