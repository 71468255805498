.main {
  overflow-x: hidden;
}
.home-top {
  .home-top_title{
    font-size: 35px;
    text-align: center;
    h1{
      margin-top: 0;
    }
  }
  @media (max-width: 500px){
    .home-top_title{
      font-size: 25px;
      font-weight: 300;
    }
  }
  .home-top__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    .home-top__item {
      //padding: 3.5rem 30px 3.5rem;
      padding: .5rem 9px 3.5rem;

      // border-radius: 30px;
      // box-shadow:  7px 7px 14px #cfcfcf,
      // -7px -7px 14px #ffffff;
      position: relative;

      margin: 5px;
      a {
        text-decoration: none;
        color: #000;
      }
      img {
        width: 250px;
        height: auto;
        transition-property: transform;
        transition-timing-function: ease;
        //border-radius: 20px;
      }
      //.unicus{
      //  transition-property: transform;
      //  transition-timing-function: ease;
      //}
      //.unicus .animated {
      //  transition-duration: 200ms;
      //  rotation: 45deg;
      //}
      .img-wrapper{
        position: relative;
        background: #547b25;
        box-shadow: 0px 7px 10px rgb(0 0 0 / 50%);
        //border-radius: 20px;
        border: solid 5px #c1ab53;
        .img_1, .img_1{
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity 1s;
          opacity: 0;
          //border-radius: 20px;
          //z-index: 2;

        }

        .info{
          width: 100%;
          height: auto;
          display: flex;
          justify-content: space-around;
          position: relative;
          bottom: 20px;
          z-index: 3;
          color: white;
          opacity: 0;
          transform: translateY(0px);
          transition: 0.5s;
          a,button{
            padding: 0.6rem;
            outline: none;
            border: none;
            border-radius: 3px;
            background: white;
            color: black;
            font-weight: bold;
            cursor: pointer;
            transition: 0.4s ease;
            //margin-left: 30px;
            &:hover{
              background: #a58b08;
              color: white;
            }
          }
        }

        .img_1:hover{
          opacity: 1;
        }
      }
      .img-wrapper:hover{
        .info{
          opacity: 1;
          transform: translateY(-60px);
        }
      }
      .home-top__item_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        
        .wrap_text{
          display: flex;
          padding: 0 0 10px;
          span{
            color: #ffffff;
            font-weight: 600;
          }
          span{
            margin: 0 auto;
          }

        }
        //left: 100px;
      }
    }
    //.terkorbeke {
    //  img {
    //    width: 730px;
    //    height: auto;
    //  }
    //}
  }
}
@media (max-width: 743px) {
  .home-top {
    .home-top__items {
      //flex-direction: column;
      .home-top__item {
        padding: 3.5rem calc(100vw / 20);
        img {
          width: 400px;
          height: auto;
        }
      }
      //.terkorbeke {
      //  img {
      //    width: 630px;
      //    height: auto;
      //  }
      //}
    }
  }
}
@media (max-width: 490px) {
  .home-top {
    .home-top__items {
      flex-direction: column;
      align-items: center;
      .home-top__item {
        padding: 1.5rem calc(100vw / 20);
        img {
          width: 330px;
          height: auto;
        }
      }
      //.terkorbeke {
      //  img {
      //    width: 530px;
      //    height: auto;
      //    margin: 0 auto;
      //  }
      //}
    }
  }
}
@media (max-width: 390px) {
  .home-top {
    .home-top__items {
      flex-direction: column;
      align-items: center;
      .home-top__item {
        padding: 1.5rem calc(100vw / 20);
        img {
          width: 300px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .home-top {
    .home-top__items {
      flex-direction: column;
      align-items: center;
      .home-top__item {
        padding: 1.5rem calc(100vw / 20);
        img {
          width: 230px;
          height: auto;
        }
      }
    }
  }
}
